import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

//STYLES
import * as StyleDesignStyles from "../styles/styleDesign";

function StyleDesign() {
    return (
        <div>
            {/* <div style={StyleDesignStyles.section}>
                <div style={StyleDesignStyles.tag}>Section 1</div>
                <Slider />
            </div>
            <div style={StyleDesignStyles.section}>
                <div style={StyleDesignStyles.tag}>Section 2</div>
                <SimpleSectionCenteredText />
            </div>
            <div style={StyleDesignStyles.section}>
                <div style={StyleDesignStyles.tag}>Section 3</div>
                <HalfPhoto />
            </div>
            <div style={StyleDesignStyles.section}>
                <div style={StyleDesignStyles.tag}>Section 4</div>
                <FourSectionsOneRow />
            </div>
            <VideoSection />
            <div style={StyleDesignStyles.section}>
                <EventsSlider />
            </div>
            <div style={StyleDesignStyles.section}>
                <div style={StyleDesignStyles.tag}>Section 5</div>
                <ReviewsInColumns />
            </div>
            <div style={StyleDesignStyles.section}>
                <div style={StyleDesignStyles.tag}>Section 6</div>
                <PhotoRow />
            </div> */}
        </div>
    );
}

export default StyleDesign;
