export const body = {
    backgroundColor: "black",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
};

export const hoursMinutes = {
    // border: "1px solid red",
    color: "white",
    fontSize: "16rem",
    textAlign: "right"
};

export const seconds = {
    // border: "1px solid red",
    fontSize: "8rem",
    position: "relative",
    color: "grey"
};

export const secondsText = {
    position: "absolute",
    bottom: "0"
};
