import React from "react";

//PODS
//--ADMINISTRATOR
import HVSAdminLoginControl from "./administration/HVSLoginForm/loginControl";

//--ADMIN

//--FRONTEND

function EnvironmentPodsMap(props) {
    return (
        <div>
            {props.podCode == "loginForm" && <HVSAdminLoginControl />}
            {/* podCode > component */}
        </div>
    );
}

export default EnvironmentPodsMap;
