import React, { useState, useEffect } from "react";
import moment from "moment";
import { Row, Col } from "react-bootstrap";

//STYLES
import * as ControllerStyles from "./styles";

function Controller() {
    const [time, setTime] = useState(moment().format("HH:mm"));
    const [seconds, setSeconds] = useState(moment().format("ss"));

    useEffect(() => {
        setInterval(() => {
            setTime(moment().format("HH:mm"));
            setSeconds(moment().format("ss"));
        }, 1000);
    }, []);

    return (
        <div style={ControllerStyles.body}>
            <Row>
                <Col sm={8} style={ControllerStyles.hoursMinutes}>
                    {time}
                </Col>
                <Col sm={4} style={ControllerStyles.seconds}>
                    <p style={ControllerStyles.secondsText}>{seconds}</p>
                </Col>
            </Row>
            {/* <div>
                <div>
                    {time} <span </span>{" "}
                </div>
            </div> */}
        </div>
    );
}

export default Controller;
